import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Input,
  Checkbox,
  Button,
  Table,
  Modal,
  Space,
  Spin,
  Switch,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import traslate from "../../../i18n/translate";
import StarRatings from "react-star-ratings";
import communityVendorService from "../../../services/community-vendor.service";
import { useSelector, useDispatch } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NotificationManager } from "react-notifications";
import {
  setBindActivePageName,
  setManageInventoryVendorId,
  setManageTabName,
} from "../../../actions/Community/ManageInventory";

export const Network = (props) => {
  const dispatch = useDispatch();
  const [isModalSendInvitationVisible, setIsModalSendInvitationVisible] =
    useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [commVendorData, setCommVendorData] = useState([]);
  const [dealerId, setDealerId] = useState("");
  const [dealerCompany, setDealerCompany] = useState("");
  const [row, setRow] = useState([]);
  const [page, setPage] = useState("RetailerAffirmation");

  const [emailLoading, setEmailLoading] = useState(false);
  const [content, setContent] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingprofileData, setLoadingProfileData] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [checkbox, setCheckBox] = useState("");
  const [checkOutOfNetwork, setCheckOutOfNetwork] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [switchValue, setswitchValue] = useState(true);
  const [sortColumnName, setSortColumnName] = useState("dealerCompany");
  const [sortType, setSortType] = useState("asc");
  const [search, setSearch] = useState([]);
  const loginDetials = useSelector((state) => state.loginReducer);

  const handleCheckOutOfNetwork = () => {
    if (props.type === "Out Of Network") {
      setCheckOutOfNetwork(true);
    }
  };
  const category = [
    {
      label: "Current Authorized Reseller",
      value: "CurrentAuthorizedReseller",
      note: ` By checking this box and clicking the submit button below I here by affirm that ${
        dealerCompany ? dealerCompany : null
      } has an open and active account with ${
        row.dealerCompany
      },that I am an authorized reseller of ${
        row.dealerCompany
      },and that I am permitted to have the ir virtual inventory on my website.`,
    },
    {
      label: "New Account Request",
      value: "NewAccountRequest",

      note: `I am interested in opening a new account with ${row.dealerCompany} and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to ${row.dealerCompany}.`,
    },
  ];

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerCompany(
            loginDetials.loginDetials.responseData.dealerCompany
          );
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleBindDataCommVendor(
            loginDetials.loginDetials.responseData.dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            search
          );
          handleCheckOutOfNetwork();
        }
      }
    }
  }, []);

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
    setSearch([]);
  }

  const columns = [
    {
      title: traslate("Vendor"),
      ...getColumnSearchProps("dealerCompany"),
      dataIndex: "dealerCompany",
      width: 250,
      sorter: (a, b) => {},
      render: (index, row) => {
        return (
          // <Link
          //   to={"/vendorProfile?isShow=true"}
          //   state={{
          //     key: "Profile",
          //     id: row.dealerID,
          //     checkOutOfNetwork: checkOutOfNetwork
          //   }}
          // >
          <span className="linkText">{row.dealerCompany}</span>
          // </Link>
        );
      },
    },

    ...(props.type == "Pending Requests"
      ? [
          {
            title: traslate("Type"),
            className: "text-nowrap",
            dataIndex: "vendorStatus",
            width: 150,
            sorter: (a, b) => {},
            showSorterTooltip: false,
            render: (index, row) => {
              return <Fragment>{row.vendorStatus}</Fragment>;
            },
          },
        ]
      : []),
    {
      title: traslate("Status"),
      dataIndex: props.type == "Pending Requests" ? "status" : "vendorStatus",
      width: 150,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (index, row) => {
        return (
          <Fragment>
            {props.type == "Pending Requests" ? row.status : row.vendorStatus}
          </Fragment>
        );
      },
    },
    {
      title: traslate("Data Rating"),
      dataIndex: "ratingCount",
      width: 125,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (index, row) => (
        <Fragment>
          <StarRatings
            rating={Number(row.ratingCount)}
            starRatedColor="#1883b8"
            starDimension="18px"
            starSpacing="0px"
            numberOfStars={5}
          />
        </Fragment>
      ),
    },
    ...(props.type !== "Pending Requests"
      ? [
          {
            title: traslate("Items"),
            className: "text-nowrap",
            dataIndex: "totalNumber",
            width: 100,
            sorter: (a, b) => {},
            showSorterTooltip: false,
            render: (value) => (value == 0 ? "-" : value),
          },
        ]
      : []),
    {
      title:
        props.type == "Pending Requests"
          ? "Last Requested"
          : traslate("Updated"),
      dataIndex: "modified",
      width: 120,
      className: "text-nowrap",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      dataIndex:
        props.type == "Pending Requests" ? "requestedDate" : "modified",
      render: (index, row) => {
        return (
          <Fragment>
            {props.type == "Pending Requests"
              ? row.requestedDate
              : row.modified}
          </Fragment>
        );
      },
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
      width: 120,
      render: (index, row) => {
        return (
          <Fragment>
            <p className="mb-0">
              {row.action == "Send Invitation" ? (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    showSendInvitationModal(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {traslate("Send Invitation")}{" "}
                </span>
              ) : row.action == "Connected" ? (
                <span> {traslate("Connected")} </span>
              ) : row.action == "Request Access" ? (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    showSendInvitationModal(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {traslate("Request Access")}{" "}
                </span>
              ) : row.action == "Re-Activate" ? (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReActiveCollection(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {traslate("Re-Activate")}{" "}
                </span>
              ) : row.action == "Re-ActivateReEnableVenor" ? (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReActiveCollection(row);
                  }}
                  className="linkText mb-0"
                  alt=""
                >
                  {" "}
                  {traslate("Re-Activate")}{" "}
                </span>
              ) : row.action == "ResubmitCancel" ? (
                <div className="action__btns">
                  <div className="mx-2">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        showSendInvitationModal(row);
                      }}
                      className="linkText mb-0"
                      alt=""
                    >
                      {" "}
                      {traslate("Resubmit")}
                    </span>
                  </div>

                  <div className="mx-2">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCancelRetailerRequest(row);
                      }}
                      className="linkText mb-0"
                      alt=""
                    >
                      {traslate("Cancel")}
                    </span>
                  </div>
                </div>
              ) : null}
            </p>
          </Fragment>
        );
      },
    },
  ];

  const showSendInvitationModal = (row) => {
    setRow(row);
    setIsModalSendInvitationVisible(true);
  };

  const handleSendInvitationModalCancel = () => {
    setCheckBox("");
    setIsModalSendInvitationVisible(false);
    setPage("RetailerAffirmation");
    setContent("");
  };

  const handleShowEmailWindow = () => {
    setPage("SendEmail");
    handleGetEmailData();
  };

  const handleSendEmail = () => {
    let inputData = {
      editval: content,
      email_Type: row.emailType,
      vendorID: row.dealerID,
      dealerID: dealerId,
    };
    communityVendorService
      .SendEmailToVendor(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("Email has been Sent Successfully.");
          handleBindDataCommVendor(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            search
          );
        } else {
          NotificationManager.error("Email has not been Sent Successfully.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    handleSendInvitationModalCancel();
  };

  const handleNewAccountRequest = () => {
    let inputData = {
      email_Type: row.emailType,
      vendorID: row.dealerID,
      dealerID: dealerId,
    };

    communityVendorService
      .SendEmailToAllEmailType(inputData)
      .then((response) => {
        let message = response.data.message;

        if (message === "Success") {
          NotificationManager.success("Email has been Sent Successfully.");
          handleBindDataCommVendor(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            search
          );
        } else {
          NotificationManager.error("Email has not been Sent Successfully.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    handleSendInvitationModalCancel();
  };

  const handleGetEmailData = () => {
    let inputData = {
      vendorID: row.dealerID,
      dealerID: dealerId,
      emailType: row.emailType,
    };
    setEmailLoading(true);
    communityVendorService
      .GeneratePopupForAffirmation(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData.sendMsgBody;
        if (message === "Success") {
          setContent(responseData);
        } else {
          setContent([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setEmailLoading(false);
      })
      .finally(() => {
        setEmailLoading(false);
      });
  };

  const handleCancelRetailerRequest = (row) => {
    try {
      let inputData = {
        vendorID: row.dealerID,
        dealerID: dealerId,
      };

      communityVendorService
        .CancelRetailerRequest(inputData)
        .then((response) => {
          let message = response.data.message;
          if (message === "Success") {
            NotificationManager.success("Request Cancelled Successfully");
            setRowDetails({});
            setSelectedColumnId(0);
            handleBindDataCommVendor(
              dealerId,
              pageNo,
              pageSize,
              sortColumnName,
              sortType,
              search
            );
          } else {
            NotificationManager.success("Request Cancelled Failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleReActiveCollection = (row) => {
    let inputData = {
      vendorID: Number(row.dealerID),
      dealerID: Number(dealerId),
      flg: 0,
    };
    communityVendorService
      .ReActiveCollection(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success("Vendor Re-Activated Successfully.");
          handleBindDataCommVendor(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            search
          );
          setSelectedColumnId(0);
        } else {
          NotificationManager.success("Vendor Not Re-Activated.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBindDataCommVendor = (
    id,
    pageNo,
    pageSize,
    sortColumnName,
    sortOrder,
    search
  ) => {
    let inputData = {
      dealerID: id,
      filerType:
        props.type === "New"
          ? "3"
          : props.type === "Out Of Network"
          ? "6"
          : props.type === "Recently Updated"
          ? "7"
          : props.type === "Archived Vendor"
          ? "5"
          : props.type === "Popular"
          ? "4"
          : props.type === "Pending Requests"
          ? "2"
          : "00",
      currentPage: pageNo,
      recordOnPage: pageSize,
      searchKeyChar: "",
      searchType: "",
      sortColumnName: sortColumnName || "dealerCompany",
      sortOrder: sortOrder || "ASC",
      lstColumnFilter: search,
    };
    setGetLoading(true);
    communityVendorService
      .BindDataCommVendor(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData.vendor_NetworkResponse !== null) {
            setCommVendorData(responseData.vendor_NetworkResponse);
            setTotalCount(responseData.totalRecords);
          } else {
            setCommVendorData(responseData.vendor_NetworkResponse);
            setTotalCount(responseData.totalRecords);
          }
        } else {
          setCommVendorData([]);
          setTotalCount(0);
        }
      })
      .catch((error) => {
        console.log(error);
        setGetLoading(false);
      })
      .finally(() => {
        setGetLoading(false);
      });
  };

  function handleCheckBoxChange(checkedValues) {
    setCheckBox(checkedValues.target.value);
  }

  const handleShowDataToRightNew = (record) => {
    let inputData = {
      vendorID: record.dealerID.toString(),
      isEnable: record.isEnabled.toString(),
      f_Type: record.filerType,
      dealerID: dealerId.toString(),
    };
    setSelectedColumnId(record.dealerID);
    dispatch(setManageInventoryVendorId(record.dealerID));
    dispatch(
      setBindActivePageName(
        props?.type == "Popular"
          ? "Popular"
          : props?.type == "Recently Updated"
          ? "Recently Updated"
          : props?.type == "Archived Vendor"
          ? "Archived Vendor"
          : props?.type == "New"
          ? "New"
          : props?.type == "Pending Requests"
          ? "Pending Requests"
          : props?.type == "Out Of Network"
          ? "Out Of Network"
          : "Network"
      )
    );
    setLoadingProfileData(true);
    communityVendorService
      .ShowDataToRightNew(inputData)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData[0];
        if (message === "Success") {
          if (responseData) {
            setRowDetails(responseData);
          } else {
            setRowDetails({});
          }
        } else {
          setRowDetails({});
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingProfileData(false);
      })
      .finally(() => {
        setLoadingProfileData(false);
      });
  };

  const handleSwitch = () => {
    setswitchValue(!switchValue);
    handleUpdateInventoryByDesignerID();
  };

  const handleUpdateInventoryByDesignerID = () => {
    let inputData = {
      designerID: selectedColumnId,
      dealerID: dealerId,
    };

    communityVendorService
      .UpdateInventoryByDesignerID(inputData)
      .then((response) => {
        let message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(
            "Connection Status Changed Successfully."
          );
          handleBindDataCommVendor(
            dealerId,
            pageNo,
            pageSize,
            sortColumnName,
            sortType,
            search
          );
          let objRowDetails = rowDetails;
          objRowDetails.isEnable = "false";
          setRowDetails({ ...objRowDetails });
          setSelectedColumnId(0);
        } else {
          NotificationManager.error("Connection Status Not Changed.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // API

  const handleTableASCDES = (pagination, filters, sorter) => {
    let type = "";
    let column = "";
    let filter = [];
    let obj = {};

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }

    if (filters.dealerCompany) {
      obj = {
        columnName: "dealerCompany",
        columnValue: filters.dealerCompany[0],
        columnType: "Input",
      };
      filter.push(obj);
    } else {
    }
    setSearch(filter);
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleBindDataCommVendor(
      dealerId,
      pagination.current,
      pagination.pageSize,
      column,
      type,
      filter
    );
    document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  const openInNewTab = () => {
    let url = rowDetails.website;
    if (rowDetails.website.includes("https")) {
      window.open(url, "_blank");
    } else if (rowDetails.website.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  const handleDispatchTabName = () => {
    if (rowDetails.isEnable == "true") {
      dispatch(setManageTabName("Manage"));
    } else {
      dispatch(setManageTabName("View"));
    }
    // For BindActive Parameter
    if (props?.type == "Popular" || "") {
      setBindActivePageName("Popular");
    } else if (props?.type == "Recently Updated" || "") {
      setBindActivePageName("Recently Updated");
    } else if (props?.type == "Archived Vendor" || "") {
      setBindActivePageName("Archived Vendor");
    } else if (props?.type == "New" || "") {
      setBindActivePageName("New");
    } else if (props?.type == "Pending Requests" || "") {
      setBindActivePageName("Pending Requests");
    } else if (props?.type == "Out Of Network" || "") {
      setBindActivePageName("Out Of Network");
    } else {
      setBindActivePageName("Network");
    }
  };

  return (
    <div className="form__fields">
      <div className="row magento__mapping permission__section">
        <div className="col-md-12">
          <div className="heading__block mb-3">
            <h4 class="workarea__heading mt-0 mb-0 d-flex">
              {" "}
              <h4 className="workarea__heading mt-0 mb-0 mx-1">
                {props.type ? traslate(props.type) : traslate("Network")}
              </h4>
              {"(" + totalCount + ")"}
            </h4>
          </div>
        </div>

        <div
          className={
            selectedColumnId !== 0
              ? "col-lg-9 divForm vendors-section"
              : "col-lg-12"
          }
        >
          <Spin spinning={getLoading}>
            <Table
              columns={columns}
              onChange={handleTableASCDES}
              dataSource={commVendorData}
              scroll={{ x: 600, y: 400 }}
              pagination={{
                total: totalCount,
                pageNo: pageNo,
                pageSize: pageSize,
                pageSizeOptions: [10, 20, 50, 100],
                responsive: true,
                showSizeChanger: true,
              }}
              onRow={(record, recordIndex) => ({
                onClick: (event) => {
                  handleShowDataToRightNew(record);
                },
              })}
              rowClassName={(record) =>
                record.dealerID == selectedColumnId ? "row-active" : ""
              }
            />
          </Spin>
        </div>
        {selectedColumnId !== 0 ? (
          <div className="col-lg-3 col-md-3">
            <Spin spinning={loadingprofileData}>
              <div className="right__section">
                <div className="profile__block">
                  <Link
                    to={"/vendorProfile"}
                    state={{
                      key: "Profile",
                      id: selectedColumnId,
                    }}
                  >
                    <span
                      onClick={() => handleDispatchTabName()}
                      className="linkText"
                    >
                      {rowDetails.designerName ? rowDetails.designerName : null}
                    </span>
                  </Link>
                  <div className="logoImg__block p-0">
                    <img src={rowDetails.src ? rowDetails.src : null} />
                  </div>
                  <p className="activeFrom">
                    {" "}
                    {rowDetails.vendorStatus
                      ? rowDetails.vendorStatus
                      : null} |{" "}
                    {rowDetails.activity ? rowDetails.activity : null}{" "}
                  </p>

                  <div className="star-ratings">
                    <p className="mb-0"> {traslate("Data Ratings")} </p>
                    <StarRatings
                      rating={rowDetails.ratingCount}
                      starRatedColor="#1883b8"
                      starDimension="18px"
                      starSpacing="0px"
                      numberOfStars={5}
                    />
                  </div>
                </div>

                <div className="links__block">
                  <ul>
                    <li onClick={() => handleDispatchTabName()}>
                      <Link
                        to={"/vendorProfile?isShow=true"}
                        state={{
                          key: "Profile",
                          id: selectedColumnId,
                          checkOutOfNetwork: checkOutOfNetwork,
                        }}
                      >
                        <i class="fa fa-address-book-o" aria-hidden="true"></i>{" "}
                        {traslate("View Profile")}
                      </Link>
                    </li>
                    <li onClick={() => handleDispatchTabName()}>
                      <Link
                        to={"/vendorProfile?isShow=true"}
                        state={{
                          key: "Conversation",
                          id: selectedColumnId,
                          checkOutOfNetwork: checkOutOfNetwork,
                        }}
                      >
                        <i class="fa fa-comments-o" aria-hidden="true"></i>{" "}
                        {traslate("Conversation")}
                      </Link>
                    </li>
                    {window.location.pathname !== "/outOfNetwork" && (
                      <>
                        {rowDetails.vendorStatus !== "Out of Network" &&
                        rowDetails.vendorStatus !== "Profile Only" ? (
                          <li onClick={() => handleDispatchTabName()}>
                            <Link
                              to={"/vendorProfile?isShow=true"}
                              state={{
                                key: "Inventory",
                                id: selectedColumnId,
                                checkOutOfNetwork: checkOutOfNetwork,
                                bindActivePageName:
                                  props?.type == "Popular"
                                    ? "Popular"
                                    : props?.type == "Recently Updated"
                                    ? "Recently Updated"
                                    : props?.type == "Archived Vendor"
                                    ? "Archived Vendor"
                                    : props?.type == "New"
                                    ? "New"
                                    : props?.type == "Pending Requests"
                                    ? "Pending Requests"
                                    : props?.type == "Out Of Network"
                                    ? "Out Of Network"
                                    : "Network",
                              }}
                            >
                              <i class="fa fa-diamond" aria-hidden="true"></i>{" "}
                              {rowDetails.isEnable == "true"
                                ? traslate("Manage Inventory")
                                : traslate("View Inventory")}
                            </Link>
                          </li>
                        ) : null}
                      </>
                    )}

                    <li onClick={() => handleDispatchTabName()}>
                      <Link
                        to={"/vendorProfile?isShow=true"}
                        state={{
                          key: "Location",
                          id: selectedColumnId,
                          checkOutOfNetwork: checkOutOfNetwork,
                        }}
                      >
                        <i class="fa fa-globe" aria-hidden="true"></i>{" "}
                        {traslate("View Location")}
                      </Link>
                      <a href="#"></a>
                    </li>
                  </ul>
                  <hr />
                </div>
                <div className="links__block">
                  {rowDetails.isEnable == "true" &&
                  window.location.href.indexOf("network") > -1 ? (
                    <div className="input__block">
                      <label> {traslate("Connection Status")} </label>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked
                        onChange={handleSwitch}
                      />
                      <hr />
                    </div>
                  ) : null}
                </div>

                <div className="retailer__dtls mb-4">
                  <p>
                    {" "}
                    {rowDetails.dealerName ? rowDetails.dealerName : null}{" "}
                  </p>
                  <a href={`mailto:${rowDetails.dealerEmail}`}>
                    {" "}
                    {rowDetails.dealerEmail
                      ? rowDetails.dealerEmail
                      : null}{" "}
                  </a>
                  <p>
                    {" "}
                    {rowDetails.dealerPhone
                      ? rowDetails.dealerPhone
                      : null}{" "}
                  </p>
                  <span className="linkText" onClick={() => openInNewTab()}>
                    {" "}
                    {rowDetails.website}
                  </span>
                  <hr />
                </div>
              </div>
            </Spin>
          </div>
        ) : null}

        <Modal
          title={
            page == "RetailerAffirmation"
              ? traslate("Retailer Affirmation")
              : traslate("Send Email")
          }
          visible={isModalSendInvitationVisible}
          className="forgot__password"
          onCancel={handleSendInvitationModalCancel}
          footer={[
            <div
              className={page == "RetailerAffirmation" ? "d-block" : "d-none"}
            >
              <Button
                key="submit"
                className={checkbox == "" ? "disabled-btn" : "primary-btn"}
                disabled={(checkbox == "") == true ? true : false}
                onClick={
                  checkbox == "CurrentAuthorizedReseller"
                    ? handleShowEmailWindow
                    : handleNewAccountRequest
                }
              >
                {traslate("Submit")}
              </Button>
            </div>,
            <div className={page == "SendEmail" ? "d-block" : "d-none"}>
              <Button
                className="primary-btn"
                key="submit"
                onClick={handleSendEmail}
              >
                {traslate("Send")}
              </Button>
            </div>,
          ]}
        >
          <div className="form__fields p-0 border-0 mh-auto">
            {page == "RetailerAffirmation" ? (
              <div>
                {category.map((item) => {
                  return (
                    <Fragment>
                      <div className="radio__block">
                        <Checkbox
                          key={item.value}
                          onChange={handleCheckBoxChange}
                          checked={item.value == checkbox}
                          value={item.value}
                          id={item.value}
                        >
                          <label htmlFor={item.value}>
                            {" "}
                            {traslate(item.label)}{" "}
                          </label>
                        </Checkbox>
                      </div>
                      <div>
                        <p> {item.note} </p>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            ) : (
              <div className="editor__section">
                <Spin spinning={emailLoading}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    onReady={(editor) => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  />
                </Spin>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default Network;
